import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 20rem 0 12rem;

  @media ${MediaQuery.isMobile} {
    padding: 12rem 0;
  }
`;

const Content = styled.section`
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  p {
    margin-bottom: 2rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;

  @media ${MediaQuery.isMobile} {
    letter-spacing: 0.2rem;
    font-size: 2rem;
    line-height: 4rem;
  }
`;

const List = styled.ul`
  margin-left: 2rem;
  list-style-type: square;
  margin-bottom: 2rem;

  li {
    line-height: 2.6rem;
  }
`;

const NestedList = styled.ul`
  margin: 1rem 3rem;
  list-style-type: square;
`;

const ModifiedText = styled.p`
  font-style: italic;
  margin-bottom: 2rem;
  color: #677a30;
`;

const MassageSchool = () => {
  return (
    <Container>
      <Content>
        <Heading>Healthcare & Bodywork School</Heading>
        <p>
          In my healthcare and bodywork school, I teach one-on-one, couples and
          groups. The teaching can include:
        </p>
        <List>
          <li>the basics of anatomy, physiology and biodynamics</li>
          <li>
            the art of therapeutic communication with your clients: what
            questions to ask and how to respond so you can properly understand
            your client's condition to meet their needs
          </li>
          <li>
            ergonomics of movement: how to use your body's power effortlessly,
            so your treatment meets your patient on a deeper level
          </li>
          <li>different spa and wellness treatments</li>
          <li>Ayurvedic and detox programs and diagnostic methods</li>
          <NestedList>
            <li>
              Nasya: facial and head massage, including cleaning of respiratory,
              auditory and visual organs by introducing medicinal oils through
              the nose/eyes/ears
            </li>
            <li>Basti: colon cleanse with specially formulated enemas</li>
            <li>
              Virechana: cleanse of small intestines with natural laxatives
            </li>
            <li>
              Shirodhara: soothing procedure for the central nervous system by
              slowly dripping oil on the forehead
            </li>
          </NestedList>
          <li>different massage & treatment modalities:</li>
          <NestedList>
            <li>
              Vishes: intensive deep tissue massage of muscles, fascia and
              ligaments
            </li>
            <li>
              Abhyanga: lymph drainage with deep oiling and slow squeezing
            </li>
            <li>
              Marma: acupressure and meridian techniques focusing on special
              vital and energetic centers of the nervous system
            </li>
            <li>Go Kizhi: massage with herbal bags</li>
            <li>
              massages focused on specific body parts (head & neck, face, belly,
              back, feet)
            </li>
          </NestedList>
        </List>
      </Content>
    </Container>
  );
};

export default MassageSchool;
