import React from "react";
import styled from "styled-components";
import jeevan from "../assets/jeevan.png";
import { MediaQuery, useDeviceType } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 8rem 0;

  @media ${MediaQuery.isMobile} {
    padding: 0;
  }
`;

const Content = styled.section`
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 4fr;

  @media ${MediaQuery.isTablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #677a30;
    padding: 6rem 1rem;
  }

  @media ${MediaQuery.isMobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #677a30;
    padding: 6rem 1rem;
  }
`;

const TextContainer = styled.article`
  background-color: #677a30;
  color: white;
  padding: 6rem 10rem;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #677a30;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-10deg);
    -ms-transform: skew(-10deg);
    transform: skew(-10deg);
    z-index: -1;
  }

  @media ${MediaQuery.isTablet} {
    position: static;
    padding: 6rem 1rem;

    &:before {
      display: none;
    }
  }

  @media ${MediaQuery.isMobile} {
    position: static;
    padding: 6rem 1rem;

    &:before {
      display: none;
    }
  }
`;

const ImageContainer = styled.figure`
  width: 100%;
  height: 100%;
  z-index: -1;

  @media ${MediaQuery.isTablet} {
    z-index: 1;
    border-radius: 99rem;
    width: 30rem;
    height: 30rem;
    overflow: hidden;
  }

  @media ${MediaQuery.isMobile} {
    z-index: 1;
    border-radius: 99rem;
    width: 30rem;
    height: 30rem;
    overflow: hidden;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
`;

const Heading = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 8rem;
  color: white;
  margin-bottom: 4rem;
  font-family: "Dancing Script", cursive;

  @media ${MediaQuery.isMobile} {
    font-size: 5rem;
  }
`;

const Text = styled.p`
  margin-bottom: 1rem;
`;

const QuoteText = styled.p`
  font-weight: 100;
  font-size: 2rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 8rem auto 0;
  padding: 0.5rem;

  &:before,
  &::after {
    position: absolute;
    color: #f1efe6;
    font-size: 8rem;
    width: 4rem;
    height: 4rem;
  }

  &:before {
    content: "“";
    opacity: 0.5;
    left: -5rem;
    top: -2rem;
  }

  &:after {
    content: "”";
    opacity: 0.5;
    right: -5rem;
    bottom: 1rem;
  }

  @media ${MediaQuery.isMobile} {
    &:before {
      left: 0;
      top: -4rem;
    }

    &:after {
      right: 0;
      bottom: 1rem;
    }
  }
`;

const Quote = styled.span`
  font-family: "Dancing Script", cursive;
  font-size: 2.4rem;
  font-weight: normal;
`;

const QuoteAuthor = styled.p`
  line-height: 3;
  text-align: center;
`;

// const List = styled.ul`
//   list-style-type: square;
// `;

// const Item = styled.li``;

const Jeevan = () => {
  const isDesktop = useDeviceType(MediaQuery.isDesktop);

  return (
    <Container id="Jeevan">
      <Content>
        {!isDesktop ? <Heading>Meet Jeevan</Heading> : null}
        <ImageContainer>
          <Image src={jeevan} alt="Jeevan-Svet" />{" "}
        </ImageContainer>
        <TextContainer>
          {isDesktop ? <Heading>Meet Jeevan</Heading> : null}
          <Text>
            My interest in life is harmony, clarity, balance and prosperity.
            Everything that I offer is on a professional level and based on many
            years of experience.
          </Text>
          <Text>
            I trained as a neurological doctor for years. I also specialize as
            an Ayurveda therapist and bodyworker for the last 18 years. Since
            then, I have worked and led various Ayurvedic Healing centres in
            Ukraine, India and other countries.
          </Text>
          <Text>
            In my work, I combine traditional and ancient methods of healing. I
            specifically offer Panchakarma Retreats for last 11 years . It's a
            customized deep therapy that will bring you back to your original
            state of health, vitality and joy of life.
          </Text>
          <Text>
            In my Ayurvedic consultations, we look at the state of your health,
            your predispositions, determine your constitution and coach you in
            the best nutrition and healthy lifestyle for your specific needs.
          </Text>
          <QuoteText>
            <Quote>
              Massage for me is art and creativity. This is the language of
              love, it is a synonym for love. Massage is one of the most subtle
              and ancient arts on earth, a magnificent area of ​​natural mutual
              support.
            </Quote>{" "}
          </QuoteText>
          <QuoteAuthor>Osho</QuoteAuthor>
          {/* <List>
            <Item>Neurology doctor</Item>
            <Item>Ayurveda Therapist</Item>
            <Item>Panchakarma Retreat Instructor</Item>
            <Item>Bodywork Practitioner </Item>
            <Item>
              Massage Coach (for professionals, beginners, and groups)
            </Item>
            <Item>Meditation and Vipassana Guide</Item>
            <Item>Herbal and Aromatherapy Therapist</Item>
            <Item>Post-Rehabilitation Movement Trainer</Item>
            <Item>Nutrition and Lifestyle Coach</Item>
          </List> */}
        </TextContainer>
      </Content>
    </Container>
  );
};

export default Jeevan;
