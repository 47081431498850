import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 20rem 0 12rem;

  @media ${MediaQuery.isMobile} {
    padding: 12rem 0;
  }
`;

const Content = styled.section`
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  p {
    margin-bottom: 2rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;

  @media ${MediaQuery.isMobile} {
    letter-spacing: 0.2rem;
    font-size: 2rem;
    line-height: 4rem;
  }
`;

const List = styled.ul`
  margin-left: 2rem;
  list-style-type: square;
  margin-bottom: 2rem;

  li {
    line-height: 2.6rem;
  }
`;

const ModifiedText = styled.p`
  font-style: italic;
  margin-bottom: 2rem;
  color: #677a30;
`;

const MassageJam = () => {
  return (
    <Container>
      <Content>
        <Heading>Massage Jam</Heading>
        <p>This is a space of healing and relaxation where we</p>
        <List>
          <li>LEARN basic techniques of Eastern & Asian massage arts</li>
          <li>
            GIVE attention and gentle care to the areas of tension in your body
          </li>
          <li>
            RECEIVE touch, movement and stretching to deeply let go, relax and
            rest
          </li>
        </List>
        <p>
          It is an opportunity to develop a deeper sense of trust (as partners
          or with a stranger) and to learn how to let yourself enjoy receiving
          and giving to each other to feel the beauty of mutual support and
          community.
        </p>
        <ModifiedText>
          All are welcome! No special skills or requirements are needed for this
          jam! You can also come alone and we will pair you up.
        </ModifiedText>
        <p>
          The jam will last two hours in total, during which you will receive 60
          minutes and give for 60 minutes.
        </p>
      </Content>
    </Container>
  );
};

export default MassageJam;
