import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 12rem 0;

  @media ${MediaQuery.isTablet} {
    padding: 6rem 0;
  }

  @media ${MediaQuery.isMobile} {
    padding: 6rem 0;
  }
`;

const Content = styled.section`
  height: 100%;
  max-width: 100rem;
  margin: 0 auto;

  @media ${MediaQuery.isTablet} {
    padding: 2rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 2rem;
  }
`;

const Heading = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;
`;

const Text = styled.p`
  text-align: center;
  margin-bottom: 2rem;

  @media ${MediaQuery.isMobile} {
    text-align: left;
  }
`;

const About = () => {
  return (
    <Container id="About">
      <Content>
        <Heading>About</Heading>
        <Text>Welcome to this space of health, happiness and prosperity.</Text>
        <Text>
          Ayurveda is a holistic, ancient but ever-unfolding science of health
          with a record of 7000 years of healing. It prevents and cures disease
          by identifying and eliminating its roots. Its methods are natural,
          effective, soft and enjoyable.
        </Text>
        <Text>It is the art of living a healthy and harmonious life.</Text>
        <Text>
          According to Ayurveda, health is the internal and external balance and
          fullness of vital energy, the body's ability to resist disease using
          the resources of the body itself.
        </Text>
      </Content>
    </Container>
  );
};

export default About;
