import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { MediaQuery, useDeviceType } from "../hooks/useDeviceType";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

const Container = styled.div`
  padding: 8rem 0;
`;

const Content = styled.section`
  height: 100%;
`;

const Heading = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 8rem;
`;

const TestimonialsContainer = styled.section`
  height: 40rem;

  .swiper {
    height: 100%;

    div {
      display: flex;
      align-items: center;
    }
  }
`;

const Testimonial = styled.article`
  height: 30rem;
  width: 100%;
  padding: 4rem;
  border-radius: 1.4rem;
  -webkit-box-shadow: 4px 5px 9px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 5px 9px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 5px 9px 6px rgba(0, 0, 0, 0.2);

  @media ${MediaQuery.isTablet} {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
  }

  @media ${MediaQuery.isMobile} {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
  }
`;

const Subheading = styled.h1`
  color: #677a30;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  font-size: 2rem;
  border-bottom: 2px solid #677a30;
  text-align: center;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`;

const Testimonials = () => {
  const isDesktop = useDeviceType(MediaQuery.isDesktop);

  return (
    <Container>
      <Content>
        <Heading>Testimonials</Heading>
        <TestimonialsContainer>
          {isDesktop ? (
            <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={3}
              className="swiper"
              autoplay={{ delay: 6000, disableOnInteraction: false }}
              loop
            >
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Tabea M.</Subheading>
                  <p>
                    I did a 15 day Panchakarma, including daily massages, with
                    Jeevan and his magic touch and his knowledgable care is
                    truly healing. He really is a master with a very accurate
                    healing channel. I felt many things shift in me.
                  </p>
                </Testimonial>
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Jimmie C.</Subheading>
                  <p>
                    I think it’s more than just physical though. Because of that
                    lightness and freshness that I’m feeling physically, it
                    transfers into how you’re feeling mentally and emotionally —
                    all of that as well.
                  </p>
                </Testimonial>
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Cecilia D.</Subheading>
                  <p>
                    Thank you so much for your expert consultations, guidance
                    and generosity. I'm feeling healthy, happy and thoroughly
                    inspired! I feel so much better already and am excited to
                    see how my body and mind reacts to my new way of life.
                  </p>
                </Testimonial>
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Kathy R.</Subheading>
                  <p>
                    I recommend panchakarma detox at Dr. Svet’s clinic to all
                    those people who care of their body and mind health. My
                    experience here has been really really great. My general
                    health improved considerably. I feel more energy and my mind
                    is more calm, clear and balanced.
                  </p>
                </Testimonial>
              </SwiperSlide>
            </Swiper>
          ) : (
            <Swiper
              modules={[Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              className="swiper"
              autoplay={{ delay: 6000, disableOnInteraction: false }}
              loop
            >
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Tabea M.</Subheading>
                  <p>
                    I did a 15 day Panchakarma, including daily massages, with
                    Jeevan and his magic touch and his knowledgable care is
                    truly healing. He really is a master with a very accurate
                    healing channel. I felt many things shift in me.
                  </p>
                </Testimonial>
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Jimmie C.</Subheading>
                  <p>
                    I think it’s more than just physical though. Because of that
                    lightness and freshness that I’m feeling physically, it
                    transfers into how you’re feeling mentally and emotionally —
                    all of that as well.
                  </p>
                </Testimonial>
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Cecilia D.</Subheading>
                  <p>
                    Thank you so much for your expert consultations, guidance
                    and generosity. I'm feeling healthy, happy and thoroughly
                    inspired! I feel so much better already and am excited to
                    see how my body and mind reacts to my new way of life.
                  </p>
                </Testimonial>
              </SwiperSlide>
              <SwiperSlide>
                <Testimonial>
                  <Subheading>Kathy R.</Subheading>
                  <p>
                    I recommend panchakarma detox at Dr. Svet’s clinic to all
                    those people who care of their body and mind health. My
                    experience here has been really really great. My general
                    health improved considerably. I feel more energy and my mind
                    is more calm, clear and balanced.
                  </p>
                </Testimonial>
              </SwiperSlide>
            </Swiper>
          )}
        </TestimonialsContainer>
      </Content>
    </Container>
  );
};

export default Testimonials;
