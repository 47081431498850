import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 20rem 0 12rem;

  @media ${MediaQuery.isMobile} {
    padding: 12rem 0;
  }
`;

const Content = styled.section`
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  p {
    margin-bottom: 2rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;

  @media ${MediaQuery.isMobile} {
    letter-spacing: 0.2rem;
    font-size: 2rem;
    line-height: 4rem;
  }
`;

const Subheading = styled.h2`
  color: #677a30;
  font-size: 2rem;
  font-style: italic;
  margin: 2rem 0;
`;

const List = styled.ul`
  margin-left: 2rem;
  list-style-type: square;

  li {
    line-height: 2.6rem;
  }
`;

const ModifiedText = styled.p`
  font-style: italic;
  margin: 2rem 0;
  color: #677a30;
`;

const Panchakarma = () => {
  return (
    <Container>
      <Content>
        <Heading>Panchakarma Detox Retreat</Heading>
        <p>
          According to Ayurveda, suffering illness and disease arises from not
          knowing one's nature, from tension and imbalance of the primary
          elements in the body as well as past negative traumatic experiences.
        </p>
        <p>
          Panchakarma therapy is a deep cleansing and rebalancing process, using
          the practical knowledge of Ayurveda to restore and rejuvenate organs
          and biosystems at the cellular level - the return to your original
          state of health, vitality and joy of Life. It works on relaxation,
          purification and rebalancing of all body tissues by using special oils
          that absorb and remove toxins from the body. It is a natural, gentle
          and enjoyable way.
        </p>
        <p>
          For 11 years, I host Panchakarma Retreats for groups and individuals
          in different retreat centres, peoples' homes, and online.
        </p>
        <p>
          If you are interested in doing a Panchakarma, you are welcome to get
          in touch. You can join an existing retreat, or we can design your
          individual treatment. Also possible remote/online. Or if you have a
          group or family (max. 6 people) we can come to you.
        </p>
        <Subheading>What are the effects of Panchakarma therapy?</Subheading>
        <List>
          <li>removes deep toxins</li>
          <li>calms the mind and nervous system</li>
          <li>normalizes metabolism in the body</li>
          <li>eliminates stiffness, cramping and pain</li>
          <li>corrects posture</li>
          <li>rehabilitates post traumatic experiences</li>
          <li>restores hormonal balance</li>
          <li>balances the primary elements in the body</li>
          <li>synchronizes biorhythm</li>
          <li>helps get rid of bad habits</li>
          <li>increases energy level</li>
          <li>slows down the aging process</li>
        </List>
        <Subheading>How does it work?</Subheading>
        <p>
          The principle of Panchakarma is the deep oiling of the body from
          inside and out.
        </p>
        <ModifiedText>Components of the treatment include:</ModifiedText>
        <List>
          <li>biorhythmic routine</li>
          <li>daily body treatments (diverse types of massages)</li>
          <li>drinking ghee or oil</li>
          <li>cleansing of the colon</li>
          <li>special nutricional diet (e.g. kitchery)</li>
          <li>body and meditation practices</li>
        </List>
        <p style={{ marginTop: 20, fontWeight: "bold" }}>
          Panchakarma "detox" can be done for 3, 7, 10, 14, or 21 days, which we
          will determine in our initial consultation.
        </p>
        <p style={{ marginTop: 20, fontStyle: "italic" }}>
          PS: Let your karma fall off you in large pieces :)
        </p>
      </Content>
    </Container>
  );
};

export default Panchakarma;
