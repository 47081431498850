import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Button from "./Button";
import { MediaQuery, useDeviceType } from "../hooks/useDeviceType";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import app from "../firebase";
import { motion } from "framer-motion";

import Modal from "./Modal/Modal";

const Container = styled.header`
  height: 12rem;
  padding: 4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  background-color: ${(props) =>
    props.changeColor ? "#ffffff" : "transparent"};

  @media ${MediaQuery.isMobile} {
    padding: 2rem;
    height: 8rem;
  }
`;

const Content = styled.section`
  max-width: 160rem;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

const Heading = styled.h1`
  display: flex;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 4rem;
  letter-spacing: 0.6rem;
  color: ${(props) => (props.changeColor ? "#677a30" : "#ffffff")};

  @media ${MediaQuery.isMobile} {
    font-size: 1.8rem;
    letter-spacing: 0.4rem;
  }
`;

const Navbar = styled.nav`
  display: flex;
  align-items: center;
`;

const Links = styled.ul`
  display: flex;
  list-style-type: none;
  margin-right: 1.4rem;

  li {
    margin: 0 1rem;
    cursor: pointer;
    color: ${(props) => (props.changeColor ? "#677a30" : "#ffffff")};
  }

  @media ${MediaQuery.isTablet} {
    position: absolute;
    top: 12rem;
    left: 0;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    align-items: center;
    padding-bottom: 1rem;

    li {
      margin: 1rem;
    }
  }

  @media ${MediaQuery.isMobile} {
    position: absolute;
    top: 8rem;
    left: 0;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    align-items: center;
    padding-bottom: 1rem;

    li {
      margin: 1rem;
    }
  }
`;

const Profile = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 6rem;
  background-image: ${(props) => (props.color ? props.color : "orange")};
  cursor: pointer;
  position: relative;
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 9rem;
  left: -4rem;
  width: 14rem;
  background-color: white;
  padding: 2rem;
  border-radius: 1.4rem;
  z-index: 1;
  border: 1px solid #677a30;
`;

const Item = styled.li`
  list-style-type: none;
  cursor: pointer;
  padding: 1rem 0;
  color: black;

  &:hover {
    color: #677a30;
  }
`;

const HamburgerContainer = styled.div`
  height: 1.6rem;
  width: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    background-color: #677a30;
    height: 0.2rem;
    width: 100%;
    border-radius: 0.5rem;
  }
`;

const Header = () => {
  const [changeColor, setChangeColor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [profileColor, setProfileColor] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const isDesktop = useDeviceType(MediaQuery.isDesktop);

  const location = useLocation();

  const node = useRef();

  function checkPosition() {
    if (isDesktop & (location.pathname === "/")) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setChangeColor(true);
      } else {
        setChangeColor(false);
      }
    } else {
      setChangeColor(true);
    }
  }

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowDropdown(false);
  };

  function signOut() {
    app.auth().signOut();
  }

  useEffect(() => {
    app.auth().onAuthStateChanged(function (user) {
      if (user) {
        app
          .firestore()
          .collection("users")
          .doc(app.auth().currentUser.uid)
          .get()
          .then((res) => {
            setProfileColor(res.data().profileImage);
          });

        setIsUser(true);
      } else {
        setIsUser(false);
      }
    });
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (isDesktop) {
      if (location.pathname !== "/") {
        setChangeColor(true);
      } else {
        setChangeColor(false);
      }
    } else {
      setChangeColor(true);
    }
    window.addEventListener("scroll", checkPosition);
  }, [isDesktop, location]);

  return (
    <Container changeColor={changeColor}>
      {showModal ? <Modal closeModal={() => setShowModal(false)} /> : null}
      <Content>
        <Link smooth to="/#Home">
          <Heading changeColor={changeColor}>Ayurveda Space</Heading>
        </Link>
        <Navbar>
          {!isDesktop ? (
            <HamburgerContainer
              onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
            >
              <div />
              <div />
              <div />
            </HamburgerContainer>
          ) : null}
          {isDesktop ? (
            <Links id="links" changeColor={changeColor}>
              <Link smooth to="/#Home">
                <li>Home</li>
              </Link>
              <Link smooth to="/#About">
                <li>About</li>
              </Link>
              <Link smooth to="/#Jeevan">
                <li>Meet Jeevan</li>
              </Link>
              <Link smooth to="/#Services">
                <li>Services</li>
              </Link>
              <Link smooth to="/#Contact">
                <li>Contact</li>
              </Link>
              {/* <Link>E-shop</Link> */}
            </Links>
          ) : showHamburgerMenu ? (
            <motion.div
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Links id="links" changeColor={changeColor}>
                <Link smooth to="/#Home">
                  <li>Home</li>
                </Link>
                <Link smooth to="/#About">
                  <li>About</li>
                </Link>
                <Link smooth to="/#Jeevan">
                  <li>Meet Jeevan</li>
                </Link>
                <Link smooth to="/#Services">
                  <li>Services</li>
                </Link>
                <Link smooth to="/#Contact">
                  <li>Contact</li>
                </Link>
                {/* <Link>E-shop</Link> */}
              </Links>
            </motion.div>
          ) : null}
          {/* {!isUser ? (
            <Button type="button" onClick={() => setShowModal(true)}>
              Sign up
            </Button>
          ) : (
            <Profile
              color={profileColor}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {showDropdown ? (
                <Dropdown ref={node}>
                  <Link to="/profile?active=profile">
                    <Item>Profile</Item>
                  </Link>
                  <Link to="/profile?active=messages">
                    <Item>Messages</Item>
                  </Link>
                  <Link to="/profile?active=testimonials">
                    <Item>Testimonials</Item>
                  </Link>
                  <Item onClick={signOut}>Sign out</Item>
                </Dropdown>
              ) : null}
            </Profile>
          )} */}
        </Navbar>
      </Content>
    </Container>
  );
};

export default Header;
