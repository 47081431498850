import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 20rem 0 12rem;

  @media ${MediaQuery.isMobile} {
    padding: 12rem 0;
  }
`;

const Content = styled.section`
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  p {
    margin-bottom: 2rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;

  @media ${MediaQuery.isMobile} {
    letter-spacing: 0.2rem;
    font-size: 2rem;
    line-height: 4rem;
  }
`;

const Subheading = styled.h2`
  color: #677a30;
  font-size: 2rem;
  font-style: italic;
  margin: 2rem 0;
`;

const List = styled.ul`
  margin-left: 2rem;
  list-style-type: square;

  li {
    line-height: 2.6rem;
  }
`;

const Lifestyle = () => {
  return (
    <Container>
      <Content>
        <Heading>Ayurvedic Health & Lifestyle Consultations</Heading>
        <p>
          In this consultation, we determine your constitution and diet and the
          routine that supports you to be in your most healthy conscious
          lifestyle.
        </p>
        <Subheading>Constitution</Subheading>
        <p>
          Every human being is a little microcosmos that consists of five basic
          elements: wind, fire, earth, water and ether, also known as Vata (wind
          and ether), Pita (fire and water), Kapha (earth and water).
        </p>
        <p>
          We are born with an inherent optimal composition of these elements
          (doshas) and throughout life (nutrition and lifestyle), these can get
          out of balance. In our diagnosis, we determine your inherent
          composition of these elements and your current constitutional balance.
          Then we design a way to rebalance your elements and bring you back to
          your optimal state.
        </p>
        <Subheading>Dinacharya (structure)</Subheading>
        <p>
          People of different constitutions have different optimal times for
          activities and taking in nutrition. Our availabilities of energy and
          digestion vary during the day, week, month, year, moon- and sun-cycle
          and are influenced by your constitution. We look at your ideal timings
          for activities such as eating, and resting so that your energy
          resources are used best. It also helps us understand how to nurture
          our body cells internally and externally.
        </p>
        <Subheading>Cleansing and Purification</Subheading>
        <p>
          Throughout life, we accumulate toxins, mucus, stagnation, tensions,
          undigested foods, cholesterin, heavy metals and parasites etc.
        </p>
        <p>
          In order to bring you back to your original state of health we need to
          clean your system. We do it in the most natural, easy and effective
          way following Ayurvedic principles.
        </p>
        <p>
          We clean your intestines, main organs (liver, spleen, gall bladder,
          lungs, brain), sensory organs (eye, ears, nose etc.), body cavities,
          liquid tissue (blood and lymphatic system) and spinal cord from
          toxins, mucus, undigested leftovers and uric acids.
        </p>
        <Subheading>Rebalancing and Stabilization of</Subheading>
        <List>
          <li>immune system</li>
          <li>hormonal gland levels</li>
          <li>blood pressure</li>
          <li>left and right side of the body</li>
          <li>posture</li>
          <li>stamina</li>
          <li>stress levels</li>
          <li>digestion</li>
          <li>metabolism</li>
          <li>energy resources</li>
        </List>
        <Subheading>Self- Bodywork Treatments</Subheading>
        <p>
          There are several bodywork treatments that you can do by yourself to
          help release tension, stagnation and blockages. Such can include
        </p>
        <List>
          <li>
            ancient inner practices (yoga, chi gong), meditations and energy
            work for deep relaxation, inner and outer balancing and the
            cultivation of inner peace and awareness
          </li>
          <li>lymphatic drainage</li>
          <li>
            correction of posture through stretching and strengthening your body
          </li>
          <li>specific treatments for cellulite and excess weight</li>
          <li>
            specific beauty programs such as skin treatments for smoothing,
            nurturing and tonus
          </li>
          <li>
            dynamic meditations for the nervous system and sensitivity (for good
            vision, hearing and body sensations)
          </li>
        </List>
      </Content>
    </Container>
  );
};

export default Lifestyle;
