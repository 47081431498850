import React from "react";
import styled from "styled-components";

const Container = styled.button`
  background-color: #677a30;
  color: #ffffff;
  border-radius: 0.6rem;
  border: none;
  max-height: 6rem;
  padding: 1.4rem;
  font-size: 1.6rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.98);
  }
`;

const Button = ({ children, onClick, type }) => {
  return (
    <Container onClick={onClick} type={type}>
      {children}
    </Container>
  );
};

export default Button;
