import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCwoOP8c2R6P5R8-XFAerz4m5ODtbh0JXA",
  authDomain: "ayurvedaspace-1ff65.firebaseapp.com",
  projectId: "ayurvedaspace-1ff65",
  storageBucket: "ayurvedaspace-1ff65.appspot.com",
  messagingSenderId: "1036297285027",
  appId: "1:1036297285027:web:6f0c1d3a92c7c7bb09fe16",
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
