import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import Button from "../components/Button";
import app from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import { AiOutlineInfoCircle, AiOutlineArrowRight } from "react-icons/ai";

import jeevanImage from "../assets/jeevan.png";

const Container = styled.div`
  padding: 20rem 0 12rem;
`;

const Content = styled.section`
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  background-color: white;
  border-radius: 1rem;
`;

const Tabs = styled.section`
  width: 40rem;
  border-right: 1px solid #677a30;
`;

const Tab = styled.div`
  padding: 2rem 3.4rem;
  background-color: ${(props) => (props.active ? "#ededed" : "transparent")};
  color: black;
  cursor: pointer;
`;

const TabContent = styled.section`
  min-height: 50rem;
  padding: 2rem;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    width: 10rem;
  }
`;

const ProfileForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  padding: 1rem;
  font-size: 1.6rem;
  border: 1px solid #677a30;
  width: 100%;
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  color: #677a30;
  margin-bottom: 2rem;
`;

const Textarea = styled.textarea`
  margin: 0 0 2rem;
  width: 100%;
  resize: none;
  font-size: 1.6rem;
  padding: 1rem;
  font-family: "Roboto", sans-serif;
  border-color: #677a30;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Chat = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50rem;
  max-height: 50rem;
  padding: 1rem;
  overflow: auto;
  overscroll-behavior: none;
`;

const Success = styled.div``;

const Error = styled.div``;

const ChatInputGroup = styled.form`
  display: flex;
  align-items: center;
  border: 1px solid #677a30;
  max-width: 90%;
  margin: 2rem auto;
  border-radius: 4rem;
  overflow: hidden;
  padding: 0.3rem;

  textarea:focus,
  input:focus {
    outline: none;
  }

  input {
    border: none;
    margin: 0;

    &::placeholder {
      font-size: 1.4rem;
    }
  }

  button {
    border-radius: 0;
  }
`;

const ChatButton = styled.button`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #677a30;
  border: none;
  color: white;
  border-radius: 4rem !important;
  cursor: pointer;
`;

const ChatLabel = styled.div`
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  padding: 2rem;

  svg {
    margin-right: 1rem;
  }
`;

const MessageSender = styled.div`
  align-self: flex-end;
  display: flex;
  max-width: 80%;
  margin-bottom: 0.6rem;

  p {
    background-color: #92b4ff;
    color: white;
    border-radius: 1rem;
    padding: 1.4rem 2rem;
    font-size: 1.6rem;
  }
`;

const MessageReceiver = styled.div`
  align-self: flex-start;
  display: flex;
  max-width: 80%;
  margin-bottom: 0.6rem;

  p {
    background-color: #e3e3e3;
    color: black;
    border-radius: 1rem;
    padding: 1.4rem 2rem;
    font-size: 1.6rem;
  }
`;

const ProfileImage = styled.div`
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 2rem;
  background-image: ${(props) => (props.color ? props.color : "orange")};
  align-self: flex-end;
  margin-left: 0.4rem;
`;

const ChatActivity = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid #677a30;
`;

const ChatActivityImage = styled.img`
  height: 4rem;
  width: 4rem;
  object-fit: cover;
  border-radius: 4rem;
  margin-right: 2rem;
`;

const JeevanImage = styled.img`
  min-height: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  border-radius: 2rem;
  align-self: flex-end;
  margin-right: 0.4rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const JeevanImagePlaceholder = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  align-self: flex-end;
  margin-right: 0.4rem;
  background-color: white;
`;

const Profile = () => {
  const [tab, setTab] = useState("");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [uid, setUid] = useState("");
  const [profileColor, setProfileColor] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const messagesStartRef = useRef(null);

  const scrollToBottomSender = () => {
    messagesStartRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  function fetchMessages() {
    const activeId = app.auth().currentUser.uid;

    setUid(activeId);

    app
      .firestore()
      .collection("users")
      .doc(app.auth().currentUser.uid)
      .get()
      .then((res) => {
        setProfileColor(res.data().profileImage);
      });

    app
      .firestore()
      .collection("chat")
      .doc(app.auth().currentUser.uid)
      .collection("messages")
      .orderBy("createdAt", "asc")
      .onSnapshot((querySnapshot) => {
        let tempArr = [];

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            tempArr.push({
              text: doc.data().text,
              id: doc.data().id,
            });
          });

          console.log(tempArr);

          const myMessages = tempArr.filter(
            (el) => el.id === app.auth().currentUser.uid
          );

          const otherMessages = tempArr.filter(
            (el) => el.id !== app.auth().currentUser.uid
          );

          const lastMessage = tempArr.find(
            (el) => el === myMessages[myMessages.length - 1]
          );

          const otherLastMessage = tempArr.find(
            (el) => el === otherMessages[otherMessages.length - 1]
          );

          if (myMessages.length > 0) {
            tempArr[tempArr.indexOf(lastMessage)].last = true;
          }
          if (otherMessages.length > 0) {
            tempArr[tempArr.indexOf(otherLastMessage)].last = true;
          }

          setMessages(tempArr);
        }
      });
  }

  function sendMessage(e) {
    e.preventDefault();

    try {
      app
        .firestore()
        .collection("chat")
        .doc(app.auth().currentUser.uid)
        .get()
        .then((res) => {
          if (res.exists) {
            app
              .firestore()
              .collection("chat")
              .doc(app.auth().currentUser.uid)
              .collection("messages")
              .add({
                text: message,
                createdAt: serverTimestamp(),
                id: app.auth().currentUser.uid,
              });

            setMessage("");
          } else {
            app
              .firestore()
              .collection("chat")
              .doc(app.auth().currentUser.uid)
              .set({
                createdAt: serverTimestamp(),
                id: app.auth().currentUser.uid,
              })
              .then(() => {
                app
                  .firestore()
                  .collection("chat")
                  .doc(app.auth().currentUser.uid)
                  .collection("messages")
                  .add({
                    text: message,
                    createdAt: serverTimestamp(),
                    id: app.auth().currentUser.uid,
                  });

                setMessage("");
              });
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  function sendTestimonial(e) {
    e.preventDefault();

    try {
      app.firestore().collection("testimonials").add({
        text: testimonial,
        createdAt: serverTimestamp(),
        id: app.auth().currentUser.uid,
        approved: false,
      });

      setTestimonial("");
      setShowSuccess(true);
    } catch {
      setShowSuccess(false);
      setShowError(true);
    }
  }

  useEffect(() => {
    setTab(searchParams.get("active"));
  }, [searchParams]);

  useEffect(() => {
    scrollToBottomSender();
  }, [messages, tab]);

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <Container>
      <Content>
        <Tabs>
          <Tab onClick={() => setTab("profile")} active={tab === "profile"}>
            Profile
          </Tab>
          <Tab onClick={() => setTab("messages")} active={tab === "messages"}>
            Messages
          </Tab>
          <Tab
            onClick={() => setTab("testimonials")}
            active={tab === "testimonials"}
          >
            Testimonials
          </Tab>
        </Tabs>
        {tab === "profile" ? (
          <TabContent>
            <Form>
              <ProfileForm>
                <InputGroup>
                  <Label>First name</Label>
                  <Input type="text" />
                </InputGroup>
                <InputGroup>
                  <Label>Last name</Label>
                  <Input type="text" />
                </InputGroup>
              </ProfileForm>
              <Button type="submit">Save</Button>
            </Form>
          </TabContent>
        ) : null}
        {tab === "messages" ? (
          <TabContent style={{ padding: 0 }}>
            <ChatLabel>
              <AiOutlineInfoCircle />
              Message Dr.Jeevan for any requests, questions, gratitude, etc.
            </ChatLabel>
            <ChatActivity>
              <ChatActivityImage src={jeevanImage} alt="jeevan" />
              <p>Dr.Jeevan Svet</p>
            </ChatActivity>
            <Chat>
              {messages.map((message, index) => (
                <>
                  {message.id === uid ? (
                    <MessageSender key={index}>
                      <p>{message.text}</p>
                      {message && message.last ? (
                        <ProfileImage color={profileColor} />
                      ) : (
                        <ProfileImage color="#fff" />
                      )}
                    </MessageSender>
                  ) : (
                    <MessageReceiver key={index}>
                      {message && message.last ? (
                        <JeevanImage src={jeevanImage} alt="jeevan" />
                      ) : (
                        <JeevanImagePlaceholder />
                      )}
                      <p>{message.text}</p>
                    </MessageReceiver>
                  )}
                  <div ref={messagesStartRef} />
                </>
              ))}
            </Chat>
            <ChatInputGroup onSubmit={(e) => sendMessage(e)}>
              <Input
                type="text"
                placeholder="Type something.."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <ChatButton type="submit">
                <AiOutlineArrowRight />
              </ChatButton>
            </ChatInputGroup>
          </TabContent>
        ) : null}
        {tab === "testimonials" ? (
          <TabContent>
            <Heading>Add testimonial</Heading>
            {showSuccess ? <Success /> : null}
            {showError ? <Error /> : null}
            <Form onSubmit={(e) => sendTestimonial(e)}>
              <Textarea
                rows="10"
                value={testimonial}
                onChange={(e) => setTestimonial(e.target.value)}
              />
              <Button type="submit">Send</Button>
            </Form>
          </TabContent>
        ) : null}
      </Content>
    </Container>
  );
};

export default Profile;
