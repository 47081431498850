import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
  }

  body {
   font-family: 'Roboto', sans-serif;
   font-size: 1.8rem;
   background-color: #f5f5f5;
  }

  h1 {
    font-size: 3.2rem;
  }



`;
