import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { MediaQuery, useDeviceType } from "../hooks/useDeviceType";

import panchakarma from "../assets/ayurveda.jpg";
import healthLifestyle from "../assets/healthy.jpg";
import workout from "../assets/yoga.jpg";
import massageJam from "../assets/massage.jpg";
import massageSchool from "../assets/massage2.jpg";

const Container = styled.div`
  padding: 12rem 0;

  @media ${MediaQuery.isTablet} {
    padding: 6rem 0;
  }
`;

const Content = styled.section`
  height: 100%;
  max-width: 100rem;
  margin: 0 auto;

  @media ${MediaQuery.isTablet} {
    padding: 0 2rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 2rem;
`;

const Subheading = styled.h2`
  font-family: "Dancing Script", cursive;
  font-size: 3.2rem;
  color: #677a30;
  margin-bottom: 1rem;

  @media ${MediaQuery.isMobile} {
    margin-top: 2rem;
  }
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Service = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 8rem 0;

  @media ${MediaQuery.isMobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ImageWrapper = styled.figure`
  height: 28rem;
  width: 28rem;
  position: relative;

  @media ${MediaQuery.isMobile} {
    width: 100%;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2rem;
`;

// const ImageBorderShadow = styled.div`
//   position: absolute;
//   top: 1.6rem;
//   left: 1.6rem;
//   height: 28rem;
//   width: 28rem;
//   border: 2px solid #677a30;
//   border-radius: 2rem;
//   z-index: -1;
// `;

const Text = styled.p``;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .link {
    color: #677a30;
    font-style: italic;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  @media ${MediaQuery.isMobile} {
    & .link {
      margin-top: 2rem;
    }
  }
`;

const ServicesText = [
  {
    image: {
      url: panchakarma,
      alt: "Panchakarma",
    },
    heading: "Panchakarma Detox",
    text: "Panchakarma therapy is a deep cleansing and rebalancing process, using the practical knowledge of Ayurveda to restore and rejuvenate organs and biosystems at the cellular level - the return to your original state of health, vitality and joy of Life.",
    url: "/panchakarma",
  },
  {
    image: {
      url: healthLifestyle,
      alt: "Health-lifestyle",
    },
    heading: "Health & Lifestyle Consultation",
    text: "Every human being is a little microcosmos that consists of five basic elements: wind, fire, earth, water and ether, also known as Vata (wind and ether), Pita (fire and water), and Kapha (earth and water). In this consultation, we determine your constitution, the diet and routine that supports you to be in your most healthy conscious lifestyle.",
    url: "/health-lifestyle",
  },
  {
    image: {
      url: workout,
      alt: "Workout",
    },
    heading: "Individual body workout",
    text: "I have 18 years of experience in these diverse practices of bodywork. In my bodywork, I constantly practice and combine Slavic, Indian, Chinese and Japanese massage techniques such as Thai, Indian, Osteopathy, specific Ayurvedic treatments, and herbal aromatherapy.",
    url: "/workout",
  },
  {
    image: {
      url: massageJam,
      alt: "Massage-jam",
    },
    heading: "Massage Jam",
    text: "It is an opportunity to develop a deep sense of trust (as partners or with a stranger) and to learn how to let yourself enjoy receiving and giving to each other - to feel the beauty of mutual support and community.",
    url: "/massage-jam",
  },
  {
    image: {
      url: massageSchool,
      alt: "Massage-school",
    },
    heading: "Massage School",
    text: "Learn the basics of anatomy, physiology, biodynamics, the art of therapeutic communication with your clients and different Spa and wellness treatments.",
    url: "/massage-school",
  },
];

const Services = () => {
  const isDesktop = useDeviceType(MediaQuery.isDesktop);

  return (
    <Container id="Services">
      <Content>
        <Heading>Services</Heading>
        <ServicesContainer>
          {ServicesText.map((service, index) => (
            <>
              {isDesktop ? (
                <Service key={index}>
                  {index % 2 === 0 ? (
                    <>
                      <ImageWrapper style={{ justifySelf: "left" }}>
                        <Image
                          src={service.image.url}
                          alt={service.image.alt}
                        />
                        {/* <ImageBorderShadow /> */}
                      </ImageWrapper>
                      <Article>
                        <div>
                          <Subheading>{service.heading}</Subheading>
                          <Text>{service.text}</Text>
                        </div>
                        <Link
                          to={service.url}
                          style={{ alignSelf: "flex-end" }}
                          className="link"
                        >
                          View more <BiChevronRight />
                        </Link>
                      </Article>
                    </>
                  ) : (
                    <>
                      <Article>
                        <div>
                          <Subheading>{service.heading}</Subheading>
                          <Text>{service.text}</Text>
                        </div>
                        <Link
                          to={service.url}
                          style={{ alignSelf: "flex-start" }}
                          className="link"
                        >
                          View more <BiChevronRight />
                        </Link>
                      </Article>
                      <ImageWrapper style={{ justifySelf: "right" }}>
                        <Image
                          src={service.image.url}
                          alt={service.image.alt}
                        />
                        {/* <ImageBorderShadow /> */}
                      </ImageWrapper>
                    </>
                  )}
                </Service>
              ) : (
                <Service key={index}>
                  <ImageWrapper style={{ justifySelf: "left" }}>
                    <Image src={service.image.url} alt={service.image.alt} />
                    {/* <ImageBorderShadow /> */}
                  </ImageWrapper>
                  <Article>
                    <div>
                      <Subheading>{service.heading}</Subheading>
                      <Text>{service.text}</Text>
                    </div>
                    <Link
                      to={service.url}
                      style={{ alignSelf: "flex-end" }}
                      className="link"
                    >
                      View more <BiChevronRight />
                    </Link>
                  </Article>
                </Service>
              )}
            </>
          ))}
        </ServicesContainer>
      </Content>
    </Container>
  );
};

export default Services;
