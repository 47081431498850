import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 20rem 0 12rem;

  @media ${MediaQuery.isMobile} {
    padding: 12rem 0;
  }
`;

const Content = styled.section`
  height: 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  p {
    margin-bottom: 2rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;

  @media ${MediaQuery.isMobile} {
    letter-spacing: 0.2rem;
    font-size: 2rem;
    line-height: 4rem;
  }
`;

const Bodywork = () => {
  return (
    <Container>
      <Content>
        <Heading>Individual Body Work</Heading>
        <p>
          I have 18 years of experience in these diverse practices of bodywork.
          In my bodywork I constantly practice and combine Slavic, Indian,
          Chinese and Japanese massage techniques such as Thai, Indian,
          Osteopathy, specific Ayurvedic treatments, herbal- and aromatherapy.
        </p>
        <p>
          I see bodywork as a space of deep relaxation, as a space to feel
          yourself and allow your self-healing powers to be activated.
        </p>
        <p>
          In my massages, I work holistically - with the body, the mind, energy,
          emotions and the soul. For me, it is not just working with the body,
          but with the internal world which lies at the core of one experiences
          Life.
        </p>
        <p>
          Touching the body is not only about touching the skin and muscles, but
          touching the experiences that a person has lived. Massage is
          travelling on the map of the body, guiding us to the parts that call
          for attention, release and reharmonization.
        </p>
      </Content>
    </Container>
  );
};

export default Bodywork;
