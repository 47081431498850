export const APP_MENU_HEIGHT = 32;
export const APP_BAR_HEIGHT = 56 + APP_MENU_HEIGHT;
export const DESKTOP_CONTENT_MAX_WIDTH = 1366;
export const DESKTOP_CONTENT_MAX_LARGE_WIDTH = 1380;

export const PROJECT_CARD_BIG_IMAGE_HEIGHT = 520;

export const TABLET_BREAKPOINT = 688;
export const SMALL_MOBILE_BREAKPOINT = 375;
export const DESKTOP_BREAKPOINT = 1024;
export const DESKTOP_MAX_WIDTH = 1492;
export const DESKTOP_MAX_LARGE_WIDTH = 1640;
export const MAX_STRING = 100;
