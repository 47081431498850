import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { IoMdClose } from "react-icons/io";
import app from "../../firebase";
import Joi from "joi";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Heading = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;
`;

const Container = styled.section`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40rem;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 1.4rem;
  padding: 3rem;

  svg {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  margin-bottom: 2rem;
  font-size: 1.6rem;
  padding: 1rem;
  border: 1px solid #677a30;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  text-align: right;
  cursor: pointer;
  color: #677a30;
  margin-top: 2rem;
  font-size: 1.6rem;
`;

const Error = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background-color: #eb2d2d;
  color: white;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

const Modal = ({ closeModal }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [rpwd, setRpwd] = useState("");
  const [error, setError] = useState("");

  const schemaLogin = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .max(254)
      .required(),
    password: Joi.string().pattern(new RegExp("^[a-zA-Z0-9]{3,30}$")),
  });

  const schemaSignUp = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .max(254)
      .required(),
    password: Joi.string().min(6).required(),
    repeat_password: Joi.ref("password"),
  }).with("password", "repeat_password");

  function handleSubmit(e) {
    e.preventDefault();

    console.log(isLogin);

    if (!isLogin) {
      const { error } = schemaSignUp.validate({
        email: email,
        password: pwd,
        repeat_password: rpwd,
      });

      if (!error) {
        app.auth().createUserWithEmailAndPassword(email, pwd);
      } else {
        if (error.message.includes("repeat_password")) {
          setError("The passwords don't match");
        } else if (error.message.includes("repeat_password")) {
        } else {
          setError(error.message);
        }
      }
    } else {
      const { error } = schemaLogin.validate({
        email: email,
        password: pwd,
      });

      if (!error) {
        app.auth().signInWithEmailAndPassword(email, pwd);
      } else {
        setError(error.message);
      }
    }

    app.auth().onAuthStateChanged(function (user) {
      if (user) {
        if (!isLogin) {
          const firstColor = Math.floor(Math.random() * 16777215).toString(16);
          const secondColor = Math.floor(Math.random() * 16777215).toString(16);

          app
            .firestore()
            .collection("users")
            .doc(app.auth().currentUser.uid)
            .set({
              profileImage: `linear-gradient(to right, #${firstColor} , #${secondColor})`,
              email: email,
            });
        }

        closeModal();
      }
    });
  }

  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    closeModal();
  };

  return (
    <Overlay>
      <Container ref={node}>
        <IoMdClose onClick={closeModal} />
        {!isLogin ? (
          <>
            <Heading>Sign up</Heading>
            {error ? <Error>{error}</Error> : null}
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Label htmlFor="email">Email</Label>
              <Input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Label htmlFor="pwd">Password</Label>
              <Input
                type="password"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                required
              />
              <Label htmlFor="rpwd">Repeat password</Label>
              <Input
                type="password"
                value={rpwd}
                onChange={(e) => setRpwd(e.target.value)}
                required
              />
              <Button type="submit">Sign up</Button>
              <Text onClick={() => setIsLogin(true)}>Switch to Login</Text>
            </Form>
          </>
        ) : (
          <>
            <Heading>Log in</Heading>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Label htmlFor="email">Email</Label>
              <Input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Label htmlFor="pwd">Password</Label>
              <Input
                type="password"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                required
              />
              <Button type="submit">Log in</Button>
              <Text onClick={() => setIsLogin(false)}>Switch to Sign up</Text>
            </Form>
          </>
        )}
      </Container>
    </Overlay>
  );
};

export default Modal;
