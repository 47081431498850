import React from "react";
import styled from "styled-components";
import bg from "../assets/leaf.jpg";
import bgMobile from "../assets/leaf-mobile.jpg";
import { MediaQuery, useDeviceType } from "../hooks/useDeviceType";

const Container = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${bg});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;

  @media ${MediaQuery.isMobile} {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${bgMobile});

    background-attachment: unset;
    background-position: right;
    background-size: 100vh 100%;
  }
`;

const Content = styled.section`
  height: 100%;
  color: #ffffff;
  max-width: 160rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${MediaQuery.isTablet} {
    padding: 5rem;
    align-items: flex-start;
  }

  @media ${MediaQuery.isMobile} {
    padding: 1.6rem;
    align-items: flex-start;
  }
`;

const Heading = styled.h1`
  font-size: 7.6rem;
  margin-bottom: 2rem;

  @media ${MediaQuery.isTablet} {
    font-size: 5.6rem;
  }

  @media ${MediaQuery.isMobile} {
    font-size: 4rem;
  }
`;

const Subheading = styled.h2`
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  margin-bottom: 10rem;

  @media ${MediaQuery.isTablet} {
    margin-bottom: 3rem;
  }

  @media ${MediaQuery.isMobile} {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    letter-spacing: 0.2rem;
  }
`;

const Text = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.6rem;

  @media ${MediaQuery.isMobile} {
    font-size: 1.3rem;
    text-align: center;
  }
`;

const Mouse = styled.div`
  position: absolute;
  left: 50%;
  bottom: 5rem;
  transform: translateX(-50%);
  z-index: 1;
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
`;

const Scroller = styled.div`
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;

  @keyframes scroll {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
      opacity: 0;
    }
  }
`;

const Subheadings = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

// const Button = styled.div`
//   margin-top: 8rem;
//   background-color: white;
//   color: black;
//   border-radius: 4rem;
//   padding: 2rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: bold;
// `;

const Home = () => {
  const isDesktop = useDeviceType(MediaQuery.isDesktop);

  return (
    <Container id="Home">
      <Content>
        <Heading>Health & Lifestyle Consultation</Heading>
        {isDesktop ? (
          <Subheading>
            Panchakarma Detox Retreats | Bodywork | Massage School
          </Subheading>
        ) : (
          <Subheadings>
            <Subheading>- Panchakarma Detox Retreats</Subheading>
            <Subheading>- Bodywork</Subheading>
            <Subheading>- Massage School</Subheading>
          </Subheadings>
        )}
        <Text>Consultations and retreats are possible online!</Text>
        {/* <Button>
          <p>Contact me</p>
        </Button> */}
      </Content>
      <Mouse>
        <Scroller />
      </Mouse>
    </Container>
  );
};

export default Home;
