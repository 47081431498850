import React from "react";
import styled from "styled-components";
import { AiOutlineWhatsApp, AiOutlineMail } from "react-icons/ai";
import Button from "../components/Button";
import { MediaQuery } from "../hooks/useDeviceType";

const Container = styled.div`
  padding: 8rem 0;
`;

const Content = styled.section`
  height: 100%;
  max-width: 100rem;
  margin: 0 auto;

  @media ${MediaQuery.isMobile} {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  color: #677a30;
  text-decoration: underline;
  text-underline-offset: 1rem;
  margin-bottom: 4rem;
`;

const Text = styled.p`
  text-align: center;
  margin-bottom: 2rem;
`;

const ContactsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const Contacts = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  font-weight: bold;

  svg {
    margin-right: 0.6rem;
  }

  @media ${MediaQuery.isMobile} {
    margin-bottom: 2rem;
  }
`;

const Form = styled.form``;

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 1rem;
  font-size: 1.6rem;
  border: 1px solid #677a30;
  width: 100%;
  margin-bottom: 2rem;
`;

const Textarea = styled.textarea`
  margin: 0 0 2rem;
  width: 100%;
  resize: vertical;
  font-size: 1.6rem;
  padding: 1rem;
  font-family: "Roboto", sans-serif;
  border-color: #677a30;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 20rem;
  }
`;

const Contact = () => {
  return (
    <Container id="Contact">
      <Content>
        <Heading>Contact</Heading>
        <Text>
          The best way to contact me is via WhatsApp or email, because I will
          get back to you faster.
        </Text>
        <ContactsContainer>
          <Contacts>
            <AiOutlineWhatsApp />
            <p>+6-694-332-0579</p>
          </Contacts>
          <Contacts>
            <AiOutlineMail />
            <p>harmonijatut@gmail.com</p>
          </Contacts>
        </ContactsContainer>
        {/* <Text>Alternatively use this contact form:</Text>
        <Form action="">
          <InputGroup>
            <Input type="text" placeholder="Email" />
            <Input type="text" placeholder="WhatsApp" />
            <Input type="text" placeholder="Name*" required />
          </InputGroup>
          <Input type="text" placeholder="Subject*" required />
          <Textarea rows="10" />
          <ButtonContainer>
            <Button>Send</Button>
          </ButtonContainer>
        </Form> */}
      </Content>
    </Container>
  );
};

export default Contact;
